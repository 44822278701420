import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import TokenList from "./views/tokenList/tokenList";
import GenerateToken from "./views/generateToken/GenerateToken";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const TokensRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/tokens"}
                to={"/tokens/list"}
            />

            {/* Routes to Tokens List*/}
            <SecuredRoute
                path={"/tokens/list"}
                component={TokenList}
                service={getServiceName('tokenService')}
                feature={getFeatureName('tokenFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/tokens/generate"}
                component={GenerateToken}
                service={getServiceName('tokenService')}
                feature={getFeatureName('tokenFeature')}
                action={getActionName('create')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
